import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

export const StyledCard = styled(Card)`
  width: 300px;
  overflow: initial !important;
  position: relative;
  box-shadow: 1px -1px 1px -1px rgba(63, 81, 181, 0.5),
    0px 1px 1px 0px rgba(63, 81, 181, 0.5),
    1px 1px 15px 0px rgba(63, 81, 181, 0.5) !important;
  padding: 20px;
`;

export const StyledCardHeader = styled(CardHeader)`
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  position: absolute;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  background-color: #3f51b5;
  display: flex;
  padding: 16px;
  display: flex;
  padding: 5px;
  align-items: center;
  text-align: center;
  width: 70%;
  padding: 3px !important;
  & div span {
    font-size: 1.2rem;
  }
`;

export const StyledCardContent = styled(CardContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 0 !important;
`;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";

interface IProps {
  activeCompany: any;
}

export default function VesselsListSubMenu(props: IProps) {
  const classes = useStyles();
  const { activeCompany } = props;

  return (
    <div className={classes.table}>
      <MaterialTable
        columns={[
          {
            title: "Vessel",
            field: "name",
            render: (rowData) => (
              <Link
                // @ts-ignore
                to={`/vessel/${rowData.id}`}
              >
                {rowData.name}
              </Link>
            ),
          },
          { title: "IMO", field: "imo" },
        ]}
        data={activeCompany.vessels}
        options={{
          search: true,
          padding: "dense",
          paging: false,
          showTitle: false,
        }}
      />
    </div>
  );
}

const useStyles = makeStyles({
  table: {
    padding: "0 5px",
    "& .MuiPaper-rounded": {
      padding: "5px",
      "& > div:last-child > div:first-child > div:first-child": {
        maxHeight: "calc(100vh - 200px)",
        overflowX: "hidden",
      },
    },
    "& .MuiToolbar-regular": {
      padding: 0,
      minHeight: "auto",
      "& > div:first-child": {
        display: "none",
      },
      "& div": {
        padding: 0,
      },
    },
    "& .MuiTableCell-root": {
      fontSize: "12px",
      maxWidth: "100px",
      padding: "5px",
    },
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
    "& .MuiTableRow-root": {
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    "& td a": {
      textDecoration: "none",
      color: "unset",
    },
  },
});

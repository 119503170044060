import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";

export default function AccountInformation(props) {
  const { allUserInfo } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={"Account Information"}
        />
        <CardContent className={classes.cardListContent}>
          <ul>
            <li>
              First name: <span>{allUserInfo.firstName}</span>
            </li>
            <li>
              Last Name: <span>{allUserInfo.lastName}</span>
            </li>
            <li>
              Email: <span>{allUserInfo.email}</span>
            </li>
            <li>
              Phone number:{" "}
              <span>
                {allUserInfo.phoneNumber ? allUserInfo.phoneNumber : "-"}
              </span>
            </li>
          </ul>
        </CardContent>
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: "0px 0px 6px 0px rgba(63, 81, 181, 0.5)",
  },
  cardHeader: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    padding: " 5px 15px",
    "& span": {
      fontSize: "17px",
    },
  },
  cardListContent: {
    "& ul": {
      paddingLeft: 0,
      listStyle: "none",
      "& li": {
        fontWeight: "bold",
        fontSize: "14px",
        marginBottom: "5px",
        "& span": {
          fontWeight: "normal",
        },
      },
    },
  },
}));

import axios from "axios";

export const postLoginForm = async (form) => {
  const { data } = await axios({
    method: "post",
    url: "https://portal.oceanicweather.com/oauth/authenticate",
    data: form,
    headers: new Headers({ "content-type": "application/json" }),
  });
  return data;
};

export const postEmailForResetPassword = async (email) => {
  const { status } = await axios({
    method: "post",
    url: "https://portal.oceanicweather.com/oauth/reset-password",
    data: { email: email },
    headers: new Headers({ "content-type": "application/json" }),
  });
  return status === 200 ? "OK" : "ERROR";
};

export const postPasswordForResetPassword = async (body) => {
  const { status } = await axios({
    method: "post",
    url: "https://portal.oceanicweather.com/api/account/reset-password",
    data: { password: body.password },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${body.token}`,
    },
  });
  return status === 200 ? "OK" : "ERROR";
};

export const getCompaniesAndVesselsData = async () => {
  const { data } = await axios.get(
    "https://portal.oceanicweather.com/api/v1/companies/",
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getUserInfo = async () => {
  const { data } = await axios.get(
    "https://portal.oceanicweather.com/api/v1/users/info/",
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getVesselDetails = async (key, id) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/vessels/id/${id}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getVesselWavesHistory = async (key, id) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/weather/waves/history/vessel/id/${id}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

export const getVesselWindHistory = async (key, id) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/weather/wind/history/vessel/id/${id}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getVesselSwellHistory = async (key, id) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/weather/swell/history/vessel/id/${id}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getVesselCurrentsHistory = async (key, id) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/weather/currents/history/vessel/id/${id}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getRouteForVessel = async (vesselId, routeId) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/vessel/${vesselId}/route/${routeId}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

export const getStatisticsForRoute = async (vesselId, locationId) => {
  const { data } = await axios.get(
    `https://portal.oceanicweather.com/api/v1/vessel/${vesselId}/daily-location/${locationId}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

import React from "react";
import { Popup } from "react-windy-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import * as httpClient from "../HttpClient/HttpClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingModal } from "../StyledComponents/MaterialComponents/LoadingModal";
import moment from "moment";

interface IProps {
  vessel: any;
  activePoint: any;
  setActivePoint(e: any): void;
}

export default function MarkerForRouteInfo(props: IProps) {
  const classes = useStyles();

  const { vessel, activePoint, setActivePoint } = props;

  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState<any>(null);

  const getStatistics = () => {
    setLoader(true);
    httpClient
      .getStatisticsForRoute(vessel.id, activePoint.dailyLocationId)
      .then((res) => setData(res))
      .catch((error) => <span>Error: {error}</span>)
      .finally(() => {
        setLoader(false);
      });
  };
  //eslint-disable-next-line
  React.useEffect(() => getStatistics(), []);

  if (loader) {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }

  return (
    <Popup
      position={[
        activePoint.dailyLocation.latitude,
        activePoint.dailyLocation.longitude,
      ]}
      onClose={() => {
        setActivePoint(null);
      }}
    >
      <div className={classes.popup}>
        <h4>
          <Link to={`/vessel/${vessel.id}`}>{vessel.name}</Link>
        </h4>
        {data ? (
          <>
            <ul>
              <li>
                <div>Wind Speed: </div>
                <div>{data.windSpeed} bft</div>
              </li>
              <li>
                <div>Wind Direction: </div>
                <div>{data.windDirection} &#176;</div>
              </li>
              <li className={classes.withLine}>
                <div>Wind Gusts: </div>
                <div>{data.windGusts} bft</div>
              </li>
              <li>
                <div>Significant Wave Height: </div>
                <div>{data.significantWaveHeight} m</div>
              </li>
              <li>
                <div>Mean Wave Direction: </div>
                <div>{data.meanWaveDirection} &#176;</div>
              </li>
              <li className={classes.withLine}>
                <div>Peak Wave Period: </div>
                <div>{data.peakWavePeriod} s</div>
              </li>
              <li>
                <div>Wave Period First Swell: </div>
                <div>{data.meanWavePeriodFirstSwell} s</div>
              </li>
              <li className={classes.withLine}>
                <div>Swell Height: </div>
                <div>{data.significantHeightTotalSwell} m</div>
              </li>
              <li>
                <div>Current Speed: </div>
                <div>{data.oceanCurrentSpeed} kn</div>
              </li>
              <li>
                <div>Current Direction: </div>
                <div>{data.oceanCurrentDirection} &#176;</div>
              </li>
            </ul>
            <div className={classes.date}>
              *Data Retrieved:
              {moment(activePoint.dailyLocation.dateTime).format(
                "YYYY-MM-DD, HH:MM"
              )}
            </div>
          </>
        ) : (
          "Error founding weather statistics"
        )}
      </div>
    </Popup>
  );
}

const useStyles = makeStyles({
  date: {
    color: "#3142a2",
    fontSize: "10px",
    fontWeight: "bold",
    marginTop: "5px",
  },
  popup: {
    "& h4": {
      margin: 0,
      textAlign: "center",
      "& a": {
        textDecoration: "none",
        color: "#3142a2!important",
      },
    },
    "& ul": {
      paddingLeft: 0,
      listStyle: "none",
      margin: "3px 0 0 0",
      "& li": {
        fontWeight: "bold",
        fontSize: "12px",
        marginBottom: "1px",
        display: "flex",
        justifyContent: "space-between",
        "& div:last-child": {
          marginLeft: "10px",
        },
      },
    },
  },
  withLine: {
    borderBottom: "thin solid #3142a2",
    marginBottom: "3px!important",
    paddingBottom: "3px",
  },
});

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login/Login";
import RedirectToLogin from "./RedirectToLogin/RedirectToLogin";
import Layout from "./Layout/Layout";
import PasswordReset from "./PasswordReset/PasswordReset";

export default function App() {
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    token === null ? false : true
  );

  const storeToken = (tokenRecieved) => {
    setToken(tokenRecieved);
  };

  React.useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token]);

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/login">
            <Login storeToken={storeToken} />
          </Route>
          <Route path="/account/reset-password">
            <PasswordReset />
          </Route>
          <RedirectToLogin isAuthenticated={isAuthenticated}>
            <Layout />
          </RedirectToLogin>
        </Switch>
      </Router>
    </div>
  );
}

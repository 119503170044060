import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "leaflet";
import vesselImage from "./pngfuel.png";
import compassImage from "./compass.png";
import MarkerDetails from "./MarkerDetails";
import { Map, ZoomControl, Marker } from "react-windy-leaflet";
import Tooltip from "@material-ui/core/Tooltip";
import MarkerForRouteInfo from "./MarkerForRouteInfo";
import RedDotIcon from "./redDot.png";
import GreenDotIcon from "./greenDot.png";
import BlueDotIcon from "./blueDot.png";

interface IProps {
  activeCompany: any;
}

// real
const windyKey = "qSA8Pqb8ejFtS130wxPfyxjbyM0bxvys";

export default function MainMap(props: IProps) {
  const classes = useStyles();
  const { activeCompany } = props;

  const [activeVessel, setActiveVessel] = React.useState<any>(null);
  const [graticules, setGraticules] = React.useState<Boolean>(true);

  const [routeForVessel, setRouteForVessel] = React.useState<any>(null);
  const [activePoint, setActivePoint] = React.useState<any>(null);

  const vesselIcon = new Icon({
    iconUrl: vesselImage,
    iconSize: [15, 15],
  });

  const redIcon = new Icon({
    iconUrl: RedDotIcon,
    iconSize: [15, 15],
  });

  const greenIcon = new Icon({
    iconUrl: GreenDotIcon,
    iconSize: [15, 15],
  });

  const blueIcon = new Icon({
    iconUrl: BlueDotIcon,
    iconSize: [15, 15],
  });

  let vesselsbounds = routeForVessel
    ? [
        [
          routeForVessel.startingPoint.latitude,
          routeForVessel.startingPoint.longitude,
        ],
        [
          routeForVessel.endingPoint.latitude,
          routeForVessel.endingPoint.longitude,
        ],
      ]
    : activeCompany.vessels.map((data) => {
        return data.latitude && data.longitude
          ? [data.latitude, data.longitude]
          : null;
      });

  let clearedBounds = vesselsbounds.filter((y) => y !== null);

  if (clearedBounds.length < 1) {
    clearedBounds = [
      [32.6949, 17.5675],
      [55.9738, 7.024],
    ];
  }

  React.useEffect(() => {
    setActiveVessel(null);
    if (document.body.classList.contains("onmenu")) {
      document.body.classList.remove("onmenu");
    }
  }, [activeCompany]);

  React.useEffect(() => {
    // if (
    //   document.body.classList.contains("overlay-rainAccu") ||
    //   document.body.classList.contains("overlay-snowAccu") ||
    //   document.body.classList.contains("overlay-satellite") ||
    //   document.body.classList.contains("overlay-fog")
    // ) {
    // @ts-ignore
    return () =>
      // @ts-ignore
      window.W["gl-particles"] && window.W["gl-particles"].close()!;
    // }
  }, []);

  return (
    <div>
      <Map
        key={1}
        className={classes.map}
        bounds={clearedBounds}
        zoomControl={true}
        windyKey={windyKey}
        // overlay={"wind"}
        graticule={!graticules}
        windyControls={true}
        overlayOpacity={0.3}
        favOverlays={[
          "wind",
          "waves",
          "gust",
          "rain",
          "temp",
          "clouds",
          "swell1",
          "swell1",
          "swell1",
          "swell1",
          "swell1",
          "currents",
          "pressure",
          "swell2",
          "gustAccu",
          "rainAccu",
          "snowAccu",
          "ptype",
          "rh",
          "hclouds",
          "mclouds",
          "lclouds",
          "fog",
          // "wwaves",
          // "cosc",
          // "dustsm",
          // "so2sm",
          "dewpoint",
        ]}
        maxZoom={8}
        mapElements={
          <>
            <Tooltip
              title="Map Menu"
              placement="left"
              classes={{
                popper: classes.tooltip,
              }}
            >
              <button
                className={`${classes.menuButton} custonMapButton`}
                onClick={() => {
                  const windyMenuButton = document.getElementById(
                    "mobile-ovr-select"
                  );
                  if (windyMenuButton !== null) {
                    windyMenuButton.click();
                  }
                }}
              >
                <span className="material-icons">settings</span>
              </button>
            </Tooltip>
            {routeForVessel !== null && (
              <Tooltip
                placement="bottom"
                title="Close Route"
                classes={{
                  popper: classes.tooltip,
                }}
              >
                <button
                  className={classes.closeRouteButton}
                  onClick={() => {
                    setRouteForVessel(null);
                    setActiveVessel(null);
                  }}
                >
                  <span className="material-icons">close</span>
                </button>
              </Tooltip>
            )}
            <Tooltip
              placement="left"
              title="Graticules"
              classes={{
                popper: classes.tooltip,
              }}
            >
              <button
                className={classes.graticuleButton}
                onClick={() => setGraticules(!graticules)}
              >
                <span className="material-icons">language</span>
              </button>
            </Tooltip>
            <ZoomControl position="bottomright" />
            {routeForVessel !== null && activeVessel !== null && (
              <>
                <Marker
                  icon={blueIcon}
                  position={[
                    routeForVessel.startingPoint.latitude,
                    routeForVessel.startingPoint.longitude,
                  ]}
                  on
                />
                <Marker
                  icon={vesselIcon}
                  position={[activeVessel.latitude, activeVessel.longitude]}
                  on
                />
                {routeForVessel.dailyLocations.map((locations, key) => {
                  return (
                    <Marker
                      key={key}
                      icon={greenIcon}
                      position={[
                        locations.dailyLocation.latitude,
                        locations.dailyLocation.longitude,
                      ]}
                      onClick={() => {
                        setActivePoint(locations);
                      }}
                      onMouseOver={() => {
                        setActivePoint(locations);
                      }}
                      on
                    />
                  );
                })}
                <Marker
                  icon={redIcon}
                  position={[
                    routeForVessel.endingPoint.latitude,
                    routeForVessel.endingPoint.longitude,
                  ]}
                  on
                />
              </>
            )}
            {activePoint !== null && routeForVessel !== null && (
              <MarkerForRouteInfo
                vessel={activeVessel}
                activePoint={activePoint}
                setActivePoint={setActivePoint}
              />
            )}
            {routeForVessel == null &&
              activeCompany.vessels.map((vessel) => {
                return (
                  vessel.latitude &&
                  vessel.longitude && (
                    <Marker
                      icon={vesselIcon}
                      key={vessel.id}
                      position={[vessel.latitude, vessel.longitude]}
                      onClick={() => {
                        setActiveVessel(vessel);
                      }}
                      onMouseOver={() => {
                        setActiveVessel(vessel);
                      }}
                      on
                    ></Marker>
                  )
                );
              })}
            {activeVessel !== null && routeForVessel == null && (
              <MarkerDetails
                vessel={activeVessel}
                setActiveVessel={setActiveVessel}
                setRouteForVessel={setRouteForVessel}
              />
            )}
            <img src={compassImage} alt="compass" className={classes.compass} />
          </>
        }
      />
    </div>
  );
}

const useStyles = makeStyles({
  tooltip: {
    zIndex: 1,
  },
  compass: {
    position: "absolute",
    left: "20px",
    bottom: "20px",
    width: "100px",
    height: "100px",
    opacity: 0.8,
  },
  closeRouteButton: {
    position: "absolute",
    borderRadius: "50%!important",
    backgroundColor: "#3f51b5c2!important",
    color: "#fff!important",
    border: "unset!important",
    width: "30px",
    height: "30px",
    display: " flex",
    alignItems: "center",
    top: "10px",
    right: "50%",
    transform: "translateX(-50%)",
    justifyContent: "space-around",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#3f51b5!important",
      color: "white!important",
      border: "unset!important",
      cursor: "pointer",
    },
  },
  menuButton: {
    position: "absolute",
    borderRadius: "50%!important",
    backgroundColor: "#3f51b5c2!important",
    color: "#fff!important",
    border: "unset!important",
    width: "30px",
    height: "30px",
    display: " flex",
    alignItems: "center",
    justifyContent: "space-around",
    top: "38px",
    right: "10px",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#3f51b5!important",
      color: "white!important",
      border: "unset!important",
      cursor: "pointer",
    },
    "& span": {
      fontSize: "17px",
    },
  },
  graticuleButton: {
    position: "absolute",
    borderRadius: "50%!important",
    backgroundColor: "#3f51b5c2!important",
    color: "#fff!important",
    border: "unset!important",
    width: "30px",
    height: "30px",
    display: " flex",
    alignItems: "center",
    justifyContent: "space-around",
    bottom: "60px",
    right: "10px",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#3f51b5!important",
      color: "white!important",
      border: "unset!important",
      cursor: "pointer",
    },
    "& span": {
      fontSize: "17px",
    },
  },
  map: {
    width: "100%",
    height: "calc(100vh - 48px)",
    marginTop: "-2px",
    "& #accumulations": {
      left: "120px!important",
      "& .selected": {
        backgroundColor: "#3f51b5a8!important",
      },
    },
    "&#windy .plugin-mobile-rhpane .plugin-content": {
      backgroundColor: "#3f51b5a8!important",
    },
    "& #plugins > div": { display: "none!important" },
    "& #plugins > div:nth-child(1)": { display: "block!important" },
    "& .menu-block": {
      backgroundColor: "#3f51b5a8!important",
    },
    "& .build-info": {
      display: "none",
    },
    "& .selected .iconfont, .transparent-switch .selected, .plugin-content::-webkit-scrollbar-thumb, #layers-levels input[type=range]::-webkit-slider-thumb": {
      backgroundColor: "#3f51b5!important",
    },
    "& #mobile-ovr-select": {
      backgroundColor: "#3f51b5bd!important",
      right: "11px!important",
      "&::before": {
        backgroundColor: "#3f51b5!important",
      },
    },
    "& #embed-zoom": {
      display: "none!important",
    },
    "& #logo": {
      opacity: 0,
      display: "none",
    },
    "& #legend-mobile": {
      height: "16px",
    },
    "& #progress-bar": {
      width: "70%",
      marginLeft: "90px",
      paddingLeft: "30px",
      "& #playpause": {
        left: "30px!important",
        boxShadow: "none!important",
        color: "#fff",
        backgroundColor: "#3f51b5c2",
      },
      "& .box": {
        backgroundColor: "#3f51b5bd!important",
        "&::before": {
          borderTopColor: "#3f51b5bd!important",
        },
      },
    },
    "& .closing-x": {
      backgroundColor: "#3f51b5!important",
      width: "30px",
      height: "30px",
    },
    "& .leaflet-control-zoom": {
      boxShadow: "none!important",
      border: "none!important",
    },
    "& .leaflet-control-zoom-in": {
      borderRadius: "50%!important",
      backgroundColor: "#3f51b5c2!important",
      color: "#fff!important",
      border: "unset!important",
      marginBottom: "38px",
      "&:hover": {
        backgroundColor: "#3f51b5!important",
        color: "white!important",
        border: "unset!important",
      },
      "&.leaflet-disabled": {
        backgroundColor: "#3f51b550!important",
        color: "white!important",
      },
    },
    "& .leaflet-control-zoom-out": {
      borderRadius: "50%!important",
      backgroundColor: "#3f51b5c2!important",
      color: "#fff!important",
      border: "unset!important",
      "&:hover": {
        backgroundColor: "#3f51b5!important",
        color: "white!important",
        border: "unset!important",
      },
      "&.leaflet-disabled": {
        backgroundColor: "#3f51b550!important",
        color: "white!important",
      },
    },
  },
});

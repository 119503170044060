// @ts-nocheck

import React from "react";
import {
  createStyles,
  fade,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";

interface IProps {
  data: any;
}

export default function SearchVessel(props: IProps) {
  const { data } = props;

  const allVessels = data.map((company) => company.vessels).flat(1);

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ["name", "imo"],
    });

  const classes = useStyles();
  const {
    getInputProps,
    getListboxProps,
    groupedOptions,
    inputValue,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    filterOptions: filterOptions,
    options: allVessels,
    getOptionLabel: (option) => option.name,
  });

  return (
    <>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          {...getInputProps()}
        />
        {groupedOptions.length > 0 && inputValue.length > 1 ? (
          <ul className={classes.listbox} {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li key={index}>
                <Link to={`/vessel/${option.id}`}>
                  {option.name + " - " + option.imo}
                </Link>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      color: "rgba(0, 0, 0, 0.54)",
      margin: "2px 0 0 0",
      zIndex: 1,
      position: "absolute",
      listStyle: "none",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      maxHeight: 200,
      boxShadow: "4px 4px 20px 1px rgba(59, 56, 199, 0.2)",
      width: "100%",
      padding: 0,
      borderRadius: "8px",
      "&::-webkit-scrollbar": {
        width: "6px",
        position: "absolute",
        left: 0,
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderTopRightRadius: "17px",
        borderBottomRightRadius: "17px",
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(0,0,0,.54)",
        borderRadius: "14px",
        width: "4px",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(0,0,0,.70)",
      },
      "& li": {
        padding: "2px 15px",
        width: "auto",
      },

      '& li[data-focus="true"]': {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        color: "rgba(0, 0, 0, 0.54)",
        cursor: "pointer",
      },
      "& li:active": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& a": {
        color: "rgba(0, 0, 0, 0.54)",
        width: "100%",
        textDecoration: "none",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

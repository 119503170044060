import React from "react";
import {
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
} from "../StyledComponents/MaterialComponents/Card";
import { StyledLoginPage } from "../StyledComponents/CustomLayouts/Login";
import LogoForLogin from "./LogoForLogin.png";
import SignIn from "./ChildComponents/SignIn";
import RequestForPasswordReset from "./ChildComponents/RequestForPasswordReset";

interface IProps {
  storeToken(token: string): void;
}

export default function Login(props: IProps) {
  const { storeToken } = props;

  const [
    requestForPasswordResetView,
    setRequestForPasswordResetView,
  ] = React.useState<boolean>(false);

  const changeToRequestForPasswordReset = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setRequestForPasswordResetView(true);
  };

  const changeToLoginView = (e) => {
    e.preventDefault();
    setRequestForPasswordResetView(false);
  };

  return (
    <StyledLoginPage>
      <div>
        <img src={LogoForLogin} alt="OceanicWeather" />
      </div>
      <StyledCard>
        <StyledCardHeader
          title={
            requestForPasswordResetView ? "Password Reset" : "Please log in"
          }
        />
        <StyledCardContent>
          {requestForPasswordResetView ? (
            <RequestForPasswordReset changeToLoginView={changeToLoginView} />
          ) : (
            <SignIn
              storeToken={storeToken}
              changeToRequestForPasswordReset={changeToRequestForPasswordReset}
            />
          )}
        </StyledCardContent>
      </StyledCard>
    </StyledLoginPage>
  );
}

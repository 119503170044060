import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { useQuery } from "react-query";
import * as httpClient from "../../HttpClient/HttpClient";
import { LoadingModal } from "../../StyledComponents/MaterialComponents/LoadingModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import EcoIcon from "@material-ui/icons/Eco";

export default function CurrentsTableResults(props) {
  const { status, data, error } = useQuery(
    ["currentsData", props.vesselId],
    httpClient.getVesselCurrentsHistory,
    { retry: false }
  );

  const classes = useStyles();

  if (status === "loading" || data === undefined) {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }
  if (status === "error") {
    // @ts-ignore
    if (error.message.includes("401")) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      // @ts-ignore
      return <span>Error: {error.message}</span>;
    }
  }

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={"Currents History"} />
      <CardContent className={classes.cardContent}>
        <div className={classes.table}>
          <MaterialTable
            data={data}
            columns={[
              {
                title: "Date",
                field: "date",
                render: (rowData) => {
                  let formatedDate = moment(rowData.date).format("YYYY-MM-DD");
                  return <span>{formatedDate}</span>;
                },
              },

              {
                title: "Ocean Current Speed",
                field: "oceanCurrentSpeed",
                render: (rowData) => (
                  <span>
                    {rowData.oceanCurrentSpeed}
                    <span> kn</span>
                  </span>
                ),
              },
              {
                title: "Ocean Current Direction",
                field: "oceanCurrentDirection",
                render: (rowData) => (
                  <span>
                    {rowData.oceanCurrentDirection}
                    <span> &#176;</span>
                  </span>
                ),
              },
              {
                title: "Document",
                field: "uri",
                render: (rowData) => {
                  return (
                    <div>
                      <Tooltip
                        title="Document"
                        disableFocusListener={rowData.uri === null}
                        classes={{
                          popper: classes.tooltip,
                        }}
                      >
                        <span>
                          <button
                            className={classes.actionButtonCurrents}
                            disabled={rowData.uri === null}
                          >
                            <a
                              href={rowData.uri}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <EcoIcon />
                            </a>
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  );
                },
              },
            ]}
            options={{
              search: true,
              padding: "dense",
              paging: false,
              showTitle: false,
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: "0px 0px 6px 0px rgba(63, 81, 181, 0.5)",
  },
  actionButtonCurrents: {
    border: "1.5px solid #ccc",
    background: "none",
    color: "#006400",
    padding: 0,
    height: "25px",
    margin: "0 3px 0 0",
    width: "25px",
    "&:disabled": {
      border: "1.5px solid #ccc!important",
      color: "#ccc!important",
    },
    "&:hover": {
      border: "1.5px solid #006400",
    },
    "& svg": {
      fontSize: "20px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      margin: "auto",
    },
  },
  cardHeader: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    padding: " 5px 15px",
    "& span": {
      fontSize: "17px",
    },
  },
  cardContent: {
    padding: "0!important",
  },
  table: {
    padding: "0 5px",
    "& > div": {
      boxShadow: "none",
    },
    "& .MuiPaper-rounded": {
      padding: "5px",
      "& > div:last-child > div:first-child > div:first-child": {
        maxHeight: "calc(100vh - 200px)",
        overflowX: "hidden",
      },
    },
    "& .MuiToolbar-regular": {
      padding: 0,
      minHeight: "auto",
      justifyContent: "flex-end",
      "& > div:first-child": {
        display: "none",
      },
      "& div": {
        padding: 0,
      },
    },
    "& .MuiTableCell-root": {
      fontSize: "12px",
      maxWidth: "100px",
      padding: "5px",
    },
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
    "& .MuiTableRow-root": {
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    "& td a": {
      textDecoration: "none",
      color: "unset",
    },
  },
  tooltip: {
    zIndex: 1,
  },
}));

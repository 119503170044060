import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

export default function RedirectToLogin(props) {
  const { isAuthenticated, children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Switch>
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
            <Redirect to="/404" />
          </Switch>
        )
      }
    />
  );
}

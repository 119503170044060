import React from "react";
import Grid from "@material-ui/core/Grid";
import WindChart from "./WindChart";
import WavesChart from "./WavesChart";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
  vesselId: number;
}

export default function Charts(props: IProps) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <div className={classes.chartsWrapper}>
        <div className={classes.chartContainer}>
          <h2>Wind Speed</h2>
          <WindChart vesselId={props.vesselId} />
        </div>
        <div className={classes.chartContainer}>
          <h2>Wave Height</h2>
          <WavesChart vesselId={props.vesselId} />
        </div>
      </div>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  chartsWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    "& h2": {
      color: "#868686",
      textAlign: "center",
      margin: "0",
      fontSize: "16px",
    },
    "& .highcharts-credits": {
      display: "none",
    },
    "& .highcharts-scrollbar-track": {
      fill: "#ccd7ec1f",
      stroke: "transparent",
    },
    "& .highcharts-scrollbar-thumb": {
      fill: "#3f51b599",
      stroke: "transparent",
    },
    "& .highcharts-scrollbar-rifles": {
      stroke: "#fff",
    },
    "& .highcharts-scrollbar-button": {
      fill: "#3f51b5",
      stroke: "transparent",
      rx: "50%",
      ry: "50%",
    },
    "& .highcharts-scrollbar-arrow": {
      fill: "#fff",
      stroke: "transparent",
    },
    "& .highcharts-button-symbol": {
      stroke: "#3f51b5",
    },
    "& .highcharts-navigator-handle": {
      stroke: "#3f51b5",
    },
  },
  chartContainer: {
    width: "400px!important",
    height: "400px!important",
    "& .highcharts-menu li:first-child, & .highcharts-menu li:last-child": {
      display: "none",
    },
  },
}));

// highcharts-container

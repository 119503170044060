import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import { useLocation } from "react-router-dom";

interface IProps {
  data: any;
  setActiveCompany: any;
  activeCompany: any;
}

export default function CompanySelection(props: IProps) {
  const { data, setActiveCompany, activeCompany } = props;
  const [disabled, setDisabled] = React.useState(false);

  const classes = useStyles();

  let location = useLocation();

  React.useEffect(() => {
    location.pathname !== "/" ? setDisabled(true) : setDisabled(false);
  }, [location]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newActiveCompany = data.filter(
      (company) => company.name === event.target.value
    )[0];
    setActiveCompany(newActiveCompany);
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <Tooltip
          title="Select Company"
          classes={{
            popper: classes.tooltip,
          }}
        >
          <Select
            disabled={disabled}
            value={activeCompany.name}
            id="company-select"
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Without label" }}
          >
            {data.map((singleCompany, key) => (
              <MenuItem value={singleCompany.name} key={key}>
                {singleCompany.name}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginLeft: "15px",
      minWidth: 120,
      "& svg": {
        color: "#fff",
      },
    },
    selectEmpty: {
      position: "relative",
      color: "#fff",
      "&::before": {
        display: "none",
      },
      "& div:focus": {
        backgroundColor: "unset",
      },
    },
    tooltip: {
      zIndex: 1,
    },
  })
);

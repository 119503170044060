import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AccountInformation from "./ChildComponents/AccountInformation";
import ChangePassword from "./ChildComponents/ChangePassword";
import TabPanel from "../VesselsProfile/ChildComponents/TabPanel";
import { useQuery } from "react-query";
import { LoadingModal } from "../StyledComponents/MaterialComponents/LoadingModal";
import * as httpClient from "../HttpClient/HttpClient";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ManageAccount(props) {
  const { status, data: allUserInfo, error } = useQuery(
    "allUserInfo",
    httpClient.getUserInfo,
    { retry: false }
  );

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  if (status === "loading") {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }
  if (status === "error") {
    // @ts-ignore
    if (error.message.includes("401")) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      // @ts-ignore
      return <span>Error: {error.message}</span>;
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.insideBox}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
          centered
        >
          <Tab label="Account Overview" {...a11yProps(0)} />
          <Tab label="Manage Account" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabContent}>
          <Grid container>
            <AccountInformation allUserInfo={allUserInfo} />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabContent}>
          <Grid container>
            <ChangePassword />
          </Grid>
        </TabPanel>
      </div>
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  insideBox: {
    display: "flex",
    margin: "0 auto",
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  pageView: {
    width: "100%",
    padding: "25px",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minHeight: "calc(100vh - 50px)",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "inherit",
    marginTop: "0",
    "& button": {
      minWidth: "auto",
      padding: "6px 25px",
    },
  },
  tabContent: {
    width: "100%",
  },
}));

import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import vesselImage from "../../Dashboard/pngfuel.png";
import { Icon } from "leaflet";
import moment from "moment";
import sampleVessel from "../sampleVessel.jpg";
import Flag from "react-world-flags";
import Tooltip from "@material-ui/core/Tooltip";

import { Map, Marker } from "react-windy-leaflet";

export default function GeneralInfo(props) {
  const { data } = props;

  const [graticules, setGraticules] = React.useState<Boolean>(false);

  // real
  const windyKey = "qSA8Pqb8ejFtS130wxPfyxjbyM0bxvys";

  const classes = useStyles();

  let vesselBounds =
    data.location !== null
      ? [[data.location.latitude, data.location.longitude]]
      : [[32.6949, 17.5675]];

  const vesselIcon = new Icon({
    iconUrl: vesselImage,
    iconSize: [15, 15],
  });

  let formatedStartDate = moment(data.contract.startDate).format("YYYY-MM-DD");

  let formatedEndDate = moment(data.contract.endDate).format("YYYY-MM-DD");

  React.useEffect(() => {
    return () =>
      data.location
        ? // @ts-ignore
          window.W["gl-particles"] && window.W["gl-particles"].close()
        : true;
  }, [data.location]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.vesselImage}>
          <div
            style={{
              backgroundImage: `url(${
                data.imageUri ? data.imageUri : sampleVessel
              })`,
            }}
          ></div>
        </Card>
      </Grid>
      {data.location && (
        <Grid item xs={12} sm={6} md={8}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              title={"Current Position"}
            />
            <CardContent className={classes.map}>
              <Map
                className={classes.map}
                bounds={vesselBounds}
                zoomControl={false}
                windyKey={windyKey}
                overlay={"wind"}
                overlayOpacity={0.3}
                graticule={graticules}
                maxZoom={8}
                windyControls={true}
                mapElements={
                  <>
                    <Tooltip
                      placement="left"
                      title="Graticules"
                      classes={{
                        popper: classes.tooltip,
                      }}
                    >
                      <button
                        id={"eraser"}
                        className={classes.graticuleButton}
                        onClick={() => setGraticules(!graticules)}
                      >
                        <span className="material-icons">language</span>
                      </button>
                    </Tooltip>
                    {data.location !== null && (
                      <Marker
                        icon={vesselIcon}
                        position={[
                          data.location.latitude,
                          data.location.longitude,
                        ]}
                        on
                      />
                    )}
                  </>
                }
              />
              )
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={"Vessel Information"}
          />
          <CardContent className={classes.cardListContent}>
            <ul>
              <li>
                Vessel name: <span>{data.name}</span>
              </li>
              <li>
                <Tooltip
                  title={data.flagIso}
                  classes={{
                    popper: classes.tooltip,
                  }}
                  placement="right"
                >
                  <div className={classes.rowWithFlag}>
                    Flag:
                    <Flag code={data.flagIso} height={"20px"} width={"25px"} />
                  </div>
                </Tooltip>
              </li>
              <li>
                IMO Number: <span>{data.imo}</span>
              </li>
              <li>
                MMSI: <span>{data.mmsi}</span>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={"Contract Information"}
          />
          <CardContent className={classes.cardListContent}>
            <ul>
              <li>
                Contract Number: <span>{data.contract.contractNumber}</span>
              </li>
              <li>
                Start Date: <span>{formatedStartDate}</span>
              </li>
              <li>
                End Date: <span>{formatedEndDate}</span>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      {data.location && (
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              title={"Current Position Information"}
            />
            <CardContent className={classes.cardListContent}>
              <ul>
                <li>
                  Longitude: <span>{data.location.longitude}</span>
                </li>
                <li>
                  Latitude: <span>{data.location.latitude}</span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  graticuleButton: {
    position: "absolute",
    borderRadius: "50%!important",
    backgroundColor: "#3f51b5c2!important",
    color: "#fff!important",
    border: "unset!important",
    width: "25px",
    height: "25px",
    display: " flex",
    alignItems: "center",
    justifyContent: "space-around",
    top: "5px",
    right: "5px",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: "#3f51b5!important",
      color: "white!important",
      border: "unset!important",
      cursor: "pointer",
    },
    "& span": {
      fontSize: "15px",
    },
  },
  tooltip: {
    zIndex: 1,
  },
  rowWithFlag: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    "& img": {
      marginLeft: "5px",
      height: "100%",
    },
  },
  vesselImage: {
    "& div": {
      height: "200px",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  },
  card: {
    boxShadow: "0px 0px 6px 0px rgba(63, 81, 181, 0.5)",
  },
  cardHeader: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    padding: " 5px 15px",
    "& span": {
      fontSize: "17px",
    },
  },
  cardListContent: {
    "& ul": {
      paddingLeft: 0,
      listStyle: "none",
      "& li": {
        fontWeight: "bold",
        fontSize: "14px",
        marginBottom: "5px",
        "& span": {
          fontWeight: "normal",
        },
      },
    },
  },
  map: {
    width: "100%",
    height: "170px",
    marginTop: "-2px",
    padding: 0,
    "& #progress-bar, #mobile-ovr-select, #embed-zoom": {
      display: "none!important",
    },
    "& #logo": {
      opacity: 0,
    },
    "& #legend-mobile": {
      height: "18px",
    },
    "& .leaflet-container": {
      width: "100%",
      height: "170px",
      marginTop: "-2px",
    },
    "& .leaflet-control-zoom": {
      boxShadow: "none",
    },
    "& .leaflet-control-zoom-in": {
      borderRadius: "50%!important",
      backgroundColor: "#3f51b5c2",
      color: "#fff",
      border: "unset",
      marginBottom: "3px",
      "&:hover": {
        backgroundColor: "#3f51b5",
        color: "white",
        border: "unset",
      },
      "&.leaflet-disabled": {
        backgroundColor: "#3f51b550",
        color: "white",
      },
    },
    "& .leaflet-control-zoom-out": {
      borderRadius: "50%!important",
      backgroundColor: "#3f51b5c2",
      color: "#fff",
      border: "unset",
      "&:hover": {
        backgroundColor: "#3f51b5",
        color: "white",
        border: "unset",
      },
      "&.leaflet-disabled": {
        backgroundColor: "#3f51b550",
        color: "white",
      },
    },
  },
}));

import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import VesselsListSubMenu from "./VesselsListSubMenu";

interface IProps {
  opened: boolean;
  setOpen: any;
  activeCompany: any;
}

export default function SideBar(props: IProps) {
  const { opened, setOpen, activeCompany } = props;
  const classes = useStyles();

  const [activeSubmenu, setActiveSubmenu] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (opened === false) {
      setActiveSubmenu(null);
    }
  }, [opened]);

  const changeActiveMenu = (submenu) => {
    setOpen(true);
    setActiveSubmenu(submenu);
  };

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: opened,
          [classes.drawerClose]: !opened,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: opened,
            [classes.drawerClose]: !opened,
          }),
        }}
      >
        <div className={classes.toolbar}></div>
        <Divider />
        <List>
          {(activeSubmenu === null || activeSubmenu === "Vessel List") && (
            <Tooltip title="Vessels list">
              <ListItem
                button
                key={"Vessel List"}
                onClick={() => changeActiveMenu("Vessel List")}
              >
                <ListItemIcon>
                  <ListAltIcon
                    color={
                      activeSubmenu === "Vessel List" ? "primary" : "inherit"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={"Vessel list"} />
              </ListItem>
            </Tooltip>
          )}
          {activeSubmenu !== null && (
            <IconButton
              color="primary"
              aria-label="close"
              onClick={() => {
                setActiveSubmenu(null);
              }}
              className={clsx(classes.menuButton)}
            >
              <HighlightOffIcon />
            </IconButton>
          )}
        </List>
        {activeSubmenu === "Vessel List" && opened && (
          <VesselsListSubMenu activeCompany={activeCompany} />
        )}
      </Drawer>
    </>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        // width: theme.spacing(9) + 1,
        width: "60px",
      },
    },
    tooltip: {
      zIndex: 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      maxHeight: "50px",
      minHeight: "48px!important",
    },
    menuButton: {
      position: "absolute",
      top: "50%",
      right: "5px",
      padding: 0,
      margin: 0,
      transform: "translateY(-50%)",
    },
  })
);

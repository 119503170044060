import React from "react";
import {
  StyledForm,
  StyledButton,
  StyledFormHelperText,
  StyledInputAdornment,
} from "../../StyledComponents/MaterialComponents/FormElements";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { StyledGrid } from "../../StyledComponents/CustomLayouts/Login";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingModal } from "../../StyledComponents/MaterialComponents/LoadingModal";
import * as httpClient from "../../HttpClient/HttpClient";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LockOpen from "@material-ui/icons/LockOpen";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useMutation } from "react-query";

export default function ChangePassword(props) {
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [showRepeatedPassword, setShowRepeatedPassword] = React.useState<
    boolean
  >(false);
  const [repeatedPassword, setRepeatedPassword] = React.useState<string>("");
  const [matchedPasswords, setMatchedPasswords] = React.useState<
    boolean | null
  >(null);
  const [passwordHasError, setPasswordHasError] = React.useState<
    boolean | null
  >(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [requestSent, setRequestSent] = React.useState<boolean>(false);

  const [mutate] = useMutation(httpClient.postPasswordForResetPassword);

  var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,16}$/;

  const handleNewPasswordChange = (prop) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
    if (passwordHasError) {
      setPasswordHasError(false);
    }
    setErrorMessage("");
    setRequestSent(false);
    setMatchedPasswords(null);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleRepeatedPasswordChange = (prop) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatedPassword(event.target.value);
    setMatchedPasswords(null);
    setErrorMessage("");
    setRequestSent(false);
  };

  const handleClickShowRepeatedPassword = () => {
    setShowRepeatedPassword(!showRepeatedPassword);
  };

  const sendNewPassword = async (e) => {
    e.preventDefault();
    if (token === null) {
      return;
    }

    setLoading(true);
    const body = {
      token: token,
      password: newPassword,
    };
    try {
      // @ts-ignore
      const data = await mutate(body);
      if (data === "OK") {
        setLoading(false);
        setRequestSent(true);
      } else {
        setLoading(false);
        setErrorMessage("Something went wrong.");
      }
    } catch {
      setLoading(false);
      setErrorMessage("Something went wrong.");
    }
  };

  const validateRepeatPassword = () => {
    setMatchedPasswords(
      newPassword !== "" &&
        repeatedPassword !== "" &&
        newPassword === repeatedPassword
    );
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader} title={"Change Password"} />
        <CardContent className={classes.cardListContent}>
          <div>
            {errorMessage !== "" && (
              <div className={classes.message}>
                <p>{errorMessage}</p>
                <p>Please try again</p>
              </div>
            )}
            {requestSent && (
              <div className={classes.successMessage}>
                <p>Your password has been changed</p>
              </div>
            )}
            <StyledForm noValidate autoComplete="off" className={classes.form}>
              <StyledGrid
                container
                spacing={1}
                alignItems="flex-end"
                className={classes.input}
              >
                <Grid item>
                  <LockOpen />
                </Grid>
                <Grid item>
                  <FormControl
                    required
                    error={passwordHasError === null ? false : passwordHasError}
                  >
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <Input
                      autoComplete={"current-password"}
                      data-testid="passwordInput"
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      onBlur={() =>
                        setPasswordHasError(
                          !regularExpression.test(newPassword)
                        )
                      }
                      onChange={handleNewPasswordChange("password")}
                      endAdornment={
                        <StyledInputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </StyledInputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                {passwordHasError && (
                  <StyledFormHelperText>
                    *At least 8 characters containing number and special
                    character
                  </StyledFormHelperText>
                )}
              </StyledGrid>
              <StyledGrid
                container
                spacing={1}
                alignItems="flex-end"
                className={classes.input}
              >
                <Grid item>
                  <LockOpen />
                </Grid>
                <Grid item>
                  <FormControl
                    required
                    error={
                      matchedPasswords === null ? false : !matchedPasswords
                    }
                  >
                    <InputLabel htmlFor="password">Repeat Password</InputLabel>
                    <Input
                      autoComplete={"current-password"}
                      data-testid="passwordInput"
                      type={showRepeatedPassword ? "text" : "password"}
                      value={repeatedPassword}
                      onChange={handleRepeatedPasswordChange("password")}
                      onBlur={validateRepeatPassword}
                      endAdornment={
                        <StyledInputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowRepeatedPassword}
                          >
                            {showRepeatedPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </StyledInputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                {matchedPasswords === false && (
                  <StyledFormHelperText>
                    *Passwords doesn't match
                  </StyledFormHelperText>
                )}
              </StyledGrid>
              <StyledButton
                variant="contained"
                color="primary"
                disabled={
                  matchedPasswords === false ||
                  matchedPasswords === null ||
                  passwordHasError === true ||
                  passwordHasError === null
                }
                onClick={(e) => sendNewPassword(e)}
              >
                Apply
              </StyledButton>
            </StyledForm>
          </div>
        </CardContent>
      </Card>
      <LoadingModal open={loading}>
        <CircularProgress />
      </LoadingModal>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      maxWidth: "240px",
      margin: "0 auto",
    },
    input: {
      width: "240px",
    },
    successMessage: {
      maxWidth: "240px",
      padding: "7px",
      color: "green",
      background: "#0080002e",
      borderRadius: "15px",
      fontSize: "13px",
      margin: "0 auto 10px",
      border: "thin solid green",
      "& p": {
        textAlign: "center",
        marginBottom: "3px",
        marginTop: 0,
      },
    },
    message: {
      maxWidth: "240px",
      padding: "7px",
      color: "red",
      background: "#ff000024",
      borderRadius: "15px",
      fontSize: "13px",
      margin: "0 auto 10px",
      border: "thin solid red",
      "& p": {
        textAlign: "center",
        marginBottom: "3px",
        marginTop: 0,
      },
    },
    card: {
      boxShadow: "0px 0px 6px 0px rgba(63, 81, 181, 0.5)",
    },
    cardHeader: {
      backgroundColor: "#3f51b5",
      color: "#fff",
      padding: " 5px 15px",
      "& span": {
        fontSize: "17px",
      },
    },
    cardListContent: {
      "& ul": {
        paddingLeft: 0,
        listStyle: "none",
        "& li": {
          fontWeight: "bold",
          fontSize: "14px",
          marginBottom: "5px",
          "& span": {
            fontWeight: "normal",
          },
        },
      },
    },
  })
);

import React from "react";
import useOutsideClick from "../../hooks/clickOutside";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export default function UserMenu(props) {
  const ref = React.useRef(null);
  useOutsideClick(ref, props.onClose);

  const classes = useStyles();

  return (
    <div>
      <div className={clsx(classes.userMenu)} ref={ref}>
        <div className={clsx(classes.userName)}>
          {props.allUserInfo.firstName + " " + props.allUserInfo.lastName}
        </div>
        <div className={clsx(classes.userMenuLinks)}>
          <Link onClick={() => props.onClose()} to={`/manage-account`}>
            My Account
          </Link>
          <Link onClick={() => localStorage.removeItem("token")} to={`/login`}>
            Log out
          </Link>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userMenuLinks: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      "& a": {
        textDecoration: "none",
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        color: "rgba(0, 0, 0, 0.87)",
        "&:hover": {
          color: "#3f51b5",
        },
      },
    },
    userName: {
      textAlign: "center",
      padding: "10px 15px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    userMenu: {
      position: "fixed",
      right: 0,
      background: "#fff",
      width: "170px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: 0,
      top: "48px",
      zIndex: 9,
    },
  })
);

// @ts-nocheck
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Link, withRouter  } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CssBaseline from "@material-ui/core/CssBaseline";
import CompanySelection from "./ChildComponents/CompanySelection";
import SideBar from "./ChildComponents/SideBar";
import SearchVessel from "./ChildComponents/SearchVessel";
import { useQuery } from "react-query";
import { LoadingModal } from "../StyledComponents/MaterialComponents/LoadingModal";
import * as httpClient from "../HttpClient/HttpClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import MainMap from "../Dashboard/MainMap";
import VesselsProfile from "../VesselsProfile/VesselsProfile";
import LogoForLayout from "./LogoForLayout.png";
import Tooltip from "@material-ui/core/Tooltip";
import ManageAccount from "../ManageAccount/ManageAccount";
import SinkingBoat from "./sinkingBoat.jpg";
import UserMenu from "./ChildComponents/UserMenu";

function Layout(props) {
  const { status, data, error } = useQuery(
    "data",
    httpClient.getCompaniesAndVesselsData,
    { retry: false }
  );
  const { userStatus, data: userInfo } = useQuery(
    "userInfo",
    httpClient.getUserInfo,
    { retry: false }
  );

  const classes = useStyles();

  const [activeCompany, setActiveCompany] = React.useState<any>(null);
  const [allUserInfo, setAllUserInfo] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [userMenuOpened, setUserMenuOpened] = React.useState(false);


  React.useEffect(() => setOpen(false), [props.history.location.pathname])

  if (status === "loading" || userStatus === "loading") {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }
  if (status === "error" || userStatus === "error") {
    if (error.message.includes("401")) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      return <span>Error: {error.message}</span>;
    }
  }

  if (activeCompany === null && data.companies) {
    setActiveCompany(data.companies[0]);
  }

  if (allUserInfo === null && userInfo) {
    setAllUserInfo(userInfo);
  }

  const closeUserMenu = () => {
    setUserMenuOpened(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            {!open ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to="/">
              <img
                className={classes.logo}
                src={LogoForLayout}
                alt="OceanicWeather"
              />
            </Link>
          </Typography>
          {data.companies && <SearchVessel data={data.companies} />}
          {activeCompany && (
            <CompanySelection
              data={data.companies}
              setActiveCompany={setActiveCompany}
              activeCompany={activeCompany}
            />
          )}
          <Tooltip
            title="User menu"
            classes={{
              popper: classes.tooltip,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="user menu"
              onClick={() => setUserMenuOpened(!userMenuOpened)}
              className={clsx(classes.userButton)}
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {activeCompany && (
        <SideBar
          opened={open}
          setOpen={setOpen}
          activeCompany={activeCompany}
        />
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {userMenuOpened && allUserInfo && (
          <UserMenu allUserInfo={allUserInfo} onClose={closeUserMenu} />
        )}

        <Switch>
          <Route exact path="/">
            {activeCompany && <MainMap activeCompany={activeCompany} />}
          </Route>
          <Route
            path="/vessel/:id"
            render={(props) => (
              <VesselsProfile
                {...props}
                setActiveCompany={setActiveCompany}
                companies={data.companies}
              />
            )}
          />
          <Route path="/manage-account">
            <ManageAccount />
          </Route>
          <Route path="*" exact={true}>
            <div className={classes.notFound}>
              {" "}
              <h2>Ooops you are inside bermuda triangle</h2>
              <h3>Please try another url to find your vessels</h3>
            </div>
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(Layout);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFound: {
      backgroundImage: `url(${SinkingBoat})`,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: "999999",
      backgroundSize: "cover",
      "& h2": {
        textAlign: "center",
        color: "#fff",
        marginBottom: "10px",
      },
      "& h3": {
        marginTop: 0,
        textAlign: "center",
        color: "#fff",
      },
    },
    userMenuLinks: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      "& a": {
        textDecoration: "none",
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        color: "rgba(0, 0, 0, 0.87)",
        "&:hover": {
          color: "#3f51b5",
        },
      },
    },
    tooltip: {
      zIndex: 1,
    },
    userName: {
      textAlign: "center",
      padding: "10px 15px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    userMenu: {
      position: "fixed",
      right: 0,
      background: "#fff",
      width: "170px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: 0,
      top: "48px",
      zIndex: 9,
    },
    userButton: {
      margin: "0 0 0 10px",
      padding: 0,
    },
    logo: {
      width: "120px",
      height: "auto",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      "& div:first-child": {
        maxHeight: "50px",
        minHeight: "auto",
      },
    },
    appBarShift: {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    root: {
      display: "flex",
    },
    menuButton: {
      marginRight: "10px",
      marginLeft: "-20px",
    },
    hide: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      "& a": {
        color: "unset",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
      },
    },

    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      minHeight: "50px!important",
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
  })
);

import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useQuery } from "react-query";
import * as httpClient from "../../HttpClient/HttpClient";
import { LoadingModal } from "../../StyledComponents/MaterialComponents/LoadingModal";
import CircularProgress from "@material-ui/core/CircularProgress";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

interface IProps {
  vesselId: number;
}

export default function WavesChart(props: IProps) {
  const { status, data, error } = useQuery(
    ["waveData", props.vesselId],
    httpClient.getVesselWavesHistory,
    { retry: false }
  );

  if (status === "loading" || data === undefined) {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }
  if (status === "error") {
    // @ts-ignore
    if (error.message.includes("401")) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      // @ts-ignore
      return <span>Error: {error.message}</span>;
    }
  }

  const sortedData = data.sort((a, b) => b.date - a.date).reverse();
  const fixedResponseForChartWaves = sortedData.map((y) => {
    return [new Date(y.date).valueOf(), y.significantWaveHeight];
  });

  const wavesData = {
    yAxis: [
      {
        title: {
          text: "Wave Height",
        },
      },
    ],
    series: [
      {
        data: fixedResponseForChartWaves,
      },
    ],
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={wavesData}
        constructorType={"stockChart"}
      />
    </>
  );
}

import styled from "styled-components";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  margin-top: 15px !important;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  right: 0;
`;

export const StyledFormHelperText = styled.p`
  color: red;
  max-width: 200px;
  margin: 0;
  font-size: 12px;
  margin-left: 35px;
  line-height: 0.9;
`;

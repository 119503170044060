import React from "react";
import { StyledGrid } from "../../StyledComponents/CustomLayouts/Login";
import {
  StyledForm,
  StyledButton,
  StyledFormHelperText,
} from "../../StyledComponents/MaterialComponents/FormElements";
import Grid from "@material-ui/core/Grid";
import MailOutline from "@material-ui/icons/MailOutline";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useMutation } from "react-query";
import * as httpClient from "../../HttpClient/HttpClient";

interface IProps {
  changeToLoginView(e: any): void;
}

export default function RequestForPasswordReset(props: IProps) {
  const { changeToLoginView } = props;

  const [mutate] = useMutation(httpClient.postEmailForResetPassword);

  const [email, setEmail] = React.useState<string>("");
  const [emailHasError, setEmailHasError] = React.useState<boolean | null>(
    null
  );
  const [requestSent, setRequestSent] = React.useState<boolean>(false);
  const classes = useStyles();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (emailHasError) {
      setEmailHasError(false);
    }
  };

  const validateEmail = () => {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,20}[\.][a-z]{2,5}/g; // eslint-disable-line
    const result = pattern.test(email);
    if (result === true) {
      setEmailHasError(false);
    } else {
      setEmailHasError(true);
    }
  };

  const sendVerificationEmail = async (e) => {
    e.preventDefault();
    if (emailHasError) {
      return;
    } else {
      try {
        const data = await mutate(email);
        if (data === "OK") {
          setRequestSent(true);
        } else {
        }
      } catch {}

      return;
    }
  };

  return (
    <div>
      {requestSent && (
        <div className={classes.message}>
          <p>If your email address exists in our database, </p>
          <p>
            {" "}
            you will receive a password recovery link at your email address in a
            few minutes.
          </p>
        </div>
      )}
      <StyledForm noValidate autoComplete="off" className={classes.mainForm}>
        <StyledGrid
          container
          spacing={1}
          alignItems="flex-end"
          className={classes.input}
        >
          <Grid item>
            <MailOutline />
          </Grid>

          <Grid item>
            <FormControl
              error={emailHasError === null ? false : emailHasError}
              required
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                autoComplete={"username"}
                data-testid="emailInput"
                value={email}
                onChange={handleEmailChange}
                onBlur={() => validateEmail()}
              />
            </FormControl>
          </Grid>
          {emailHasError && (
            <StyledFormHelperText>*Not a valid email</StyledFormHelperText>
          )}
        </StyledGrid>

        <StyledButton
          variant="contained"
          color="primary"
          disabled={email === "" || emailHasError}
          onClick={(e) => sendVerificationEmail(e)}
          // data-testid="loginButton"
        >
          Apply
        </StyledButton>
      </StyledForm>
      <div className={classes.backToLogin}>
        <button onClick={(e) => changeToLoginView(e)}>Login</button>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  mainForm: {
    width: "240px",
    margin: "auto",
  },
  backToLogin: {
    textAlign: "center",
    "& button": {
      color: "#3f51b5",
      backgroundColor: "unset",
      border: "none",
      marginTop: "10px",
      "&:hover": { cursor: "pointer" },
      "&:focus": { outline: "none" },
    },
  },
  message: {
    padding: "7px",
    color: "green",
    background: "#0080002e",
    borderRadius: "15px",
    fontSize: "13px",
    marginTop: "10px",
    border: "thin solid green",
    "& p": {
      textAlign: "center",
      marginBottom: "3px",
      marginTop: 0,
    },
  },
  input: {
    width: "240px",
  },
}));

import React from "react";
import { StyledGrid } from "../../StyledComponents/CustomLayouts/Login";
import {
  StyledForm,
  StyledButton,
  StyledInputAdornment,
  StyledFormHelperText,
} from "../../StyledComponents/MaterialComponents/FormElements";
import Grid from "@material-ui/core/Grid";
import MailOutline from "@material-ui/icons/MailOutline";
import LockOpen from "@material-ui/icons/LockOpen";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingModal } from "../../StyledComponents/MaterialComponents/LoadingModal";
import * as httpClient from "../../HttpClient/HttpClient";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useMutation } from "react-query";

interface IProps {
  storeToken(token: string): void;
  changeToRequestForPasswordReset(e: any): void;
}

interface IState {
  email: string;
  password: string;
  showPassword: boolean;
}

export interface IRequestBody {
  username: string;
  password: string;
}

export default function SignIn(props: IProps) {
  const { storeToken, changeToRequestForPasswordReset } = props;
  const classes = useStyles();

  const [mutate] = useMutation(httpClient.postLoginForm);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [emailHasError, setEmailHasError] = React.useState<boolean | null>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const handlePasswordChange = (prop: keyof IState) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorMessage("");
    setPassword(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setErrorMessage("");
    if (emailHasError) {
      setEmailHasError(false);
    }
  };

  const validateEmail = () => {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,20}[\.][a-z]{2,5}/g; // eslint-disable-line
    const result = pattern.test(email);
    if (result === true) {
      setEmailHasError(false);
    } else {
      setEmailHasError(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const submitLoginForm = async (e) => {
    e.preventDefault();
    if (emailHasError) {
      return;
    }
    localStorage.removeItem("token");
    const body: IRequestBody = {
      username: email,
      password: password,
    };

    setLoading(true);
    try {
      const data = await mutate(body);
      if (data) {
        const token = data.token;
        storeToken(token);
        localStorage.setItem("token", token);
        setLoading(false);
        history.replace(from);
      } else {
        setLoading(false);
        setErrorMessage("Invalid credentials.");
      }
    } catch {
      setLoading(false);
      setErrorMessage("Something went wrong.");
    }
  };

  return (
    <div>
      {errorMessage !== "" && (
        <div className={classes.message}>
          <p>{errorMessage}</p>
          <p>Please try again</p>
        </div>
      )}
      <StyledForm noValidate autoComplete="off">
        <StyledGrid
          container
          spacing={1}
          alignItems="flex-end"
          className={classes.input}
        >
          <Grid item>
            <MailOutline />
          </Grid>
          <Grid item>
            <FormControl
              error={emailHasError === null ? false : emailHasError}
              required
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                autoComplete={"email"}
                data-testid="emailInput"
                value={email}
                onChange={handleEmailChange}
                onBlur={() => validateEmail()}
              />
            </FormControl>
          </Grid>
          {emailHasError && (
            <StyledFormHelperText>*Not a valid email</StyledFormHelperText>
          )}
        </StyledGrid>
        <StyledGrid
          container
          spacing={1}
          alignItems="flex-end"
          className={classes.input}
        >
          <Grid item>
            <LockOpen />
          </Grid>
          <Grid item>
            <FormControl required>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                autoComplete={"current-password"}
                data-testid="passwordInput"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange("password")}
                endAdornment={
                  <StyledInputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </StyledInputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </StyledGrid>
        <StyledButton
          variant="contained"
          color="primary"
          disabled={email === "" || password === "" || emailHasError}
          onClick={submitLoginForm}
          data-testid="loginButton"
        >
          Log in
        </StyledButton>
      </StyledForm>
      <div className={classes.passReset}>
        <button onClick={(e) => changeToRequestForPasswordReset(e)}>
          Reset Password
        </button>
      </div>
      {loading && (
        <LoadingModal open={true}>
          <CircularProgress />
        </LoadingModal>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    padding: "7px",
    color: "red",
    background: "#ff000024",
    borderRadius: "15px",
    fontSize: "13px",
    marginTop: "10px",
    border: "thin solid red",
    "& p": {
      textAlign: "center",
      marginBottom: "3px",
      marginTop: 0,
    },
  },
  passReset: {
    textAlign: "center",
    "& button": {
      color: "#3f51b5",
      backgroundColor: "unset",
      border: "none",
      marginTop: "10px",
      "&:hover": { cursor: "pointer" },
      "&:focus": { outline: "none" },
    },
  },
  input: {
    width: "240px",
  },
}));

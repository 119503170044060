import styled from "styled-components";
import Modal from "@material-ui/core/Modal";

export const LoadingModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div:first-child {
    background-color: rgba(0, 0, 0, 0.2);
  }
  & svg:focus,
  div {
    outline: none;
  }
`;

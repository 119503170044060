import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const StyledLoginPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & img {
    width: 230px;
    height: 65px;
    margin-bottom: 30px;
    margin-top: -65px;
  }
`;

export const StyledGrid = styled(Grid)`
  margin: 3px 0 !important;
`;

import React from "react";
import { Popup } from "react-windy-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import * as httpClient from "../HttpClient/HttpClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingModal } from "../StyledComponents/MaterialComponents/LoadingModal";
import TimelineIcon from "@material-ui/icons/Timeline";

interface IProps {
  vessel: any;
  setActiveVessel: any;
  setRouteForVessel: any;
}

export default function MarkerDetails(props: IProps) {
  const classes = useStyles();

  const { vessel, setActiveVessel, setRouteForVessel } = props;

  const [loader, setLoader] = React.useState(false);

  const getRoute = () => {
    setLoader(true);
    httpClient
      .getRouteForVessel(vessel.id, vessel.routeId)
      .then((res) => setRouteForVessel(res))
      .catch((error) => <span>Error: {error}</span>)
      .finally(() => {
        setLoader(false);
      });
  };

  if (loader) {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }

  return (
    <Popup
      position={[vessel.latitude, vessel.longitude]}
      onClose={() => {
        setActiveVessel(null);
      }}
    >
      <div className={classes.popup}>
        <h4>
          <Link to={`/vessel/${vessel.id}`}>{vessel.name}</Link>
        </h4>
        <ul>
          <li>
            Longitude: <span>{vessel.longitude}</span>
          </li>
          <li>
            Latitude: <span>{vessel.latitude}</span>
          </li>
        </ul>
        <div onClick={getRoute} className={classes.routeButton}>
          <TimelineIcon />
          <div>Vessel route</div>
        </div>
      </div>
    </Popup>
  );
}

const useStyles = makeStyles({
  routeButton: {
    display: "flex",
    marginTop: "3px",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& div:last-child": {
        color: "#3142a2",
      },
    },
    "& svg": {
      color: "#3142a2",
    },
    "& div:last-child": {
      color: "#949494",
      fontWeight: "bold",
      fontSize: "13px",
      marginLeft: "5px",
    },
  },
  popup: {
    "& h4": {
      margin: 0,
      textAlign: "center",
      "& a": {
        textDecoration: "none",
        color: "#3142a2!important",
      },
    },
    "& ul": {
      paddingLeft: 0,
      listStyle: "none",
      margin: "3px 0 0 0",
      "& li": {
        fontWeight: "bold",
        fontSize: "11px",
        marginBottom: "1px",
      },
    },
  },
});

import React from "react";
import matchSorter from "match-sorter";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as httpClient from "../HttpClient/HttpClient";
import { useQuery } from "react-query";
import { LoadingModal } from "../StyledComponents/MaterialComponents/LoadingModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import TabPanel from "./ChildComponents/TabPanel";
import GeneralInfo from "./ChildComponents/GeneralInfo";
import Charts from "./ChildComponents/Charts";
import WavesTableResults from "./ChildComponents/WavesTableResults";
import WindTableResults from "./ChildComponents/WindTableResults";
import SwellsTableResults from "./ChildComponents/SwellsTableResults";
import CurrentsTableResults from "./ChildComponents/CurrentsTableResults";

export default function VesselsProfile(props) {
  const { companies, setActiveCompany } = props;
  const filterOptions = (id) =>
    matchSorter(companies, id, {
      keys: [(company) => company.vessels.map((vessel) => vessel.id)],
    });

  const newActiveCompany = filterOptions(props.match.params.id);

  const { status, data, error } = useQuery(
    ["vessel", props.match.params.id],
    httpClient.getVesselDetails,
    { retry: false }
  );

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => setActiveCompany(newActiveCompany[0]), [
    newActiveCompany,
    setActiveCompany,
  ]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (status === "loading") {
    return (
      <LoadingModal open={true}>
        <CircularProgress />
      </LoadingModal>
    );
  }
  if (status === "error") {
    // @ts-ignore
    if (error.message.includes("401")) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      // @ts-ignore
      return <span>Error: {error.message}</span>;
    }
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        centered
      >
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Wind" {...a11yProps(1)} />
        <Tab label="Waves" {...a11yProps(2)} />
        <Tab label="Swells" {...a11yProps(3)} />
        <Tab label="Currents" {...a11yProps(4)} />
        <Tab label="Charts" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabContent}>
        <GeneralInfo data={data} />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabContent}>
        <WindTableResults vesselId={props.match.params.id} />
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabContent}>
        <WavesTableResults vesselId={props.match.params.id} />
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.tabContent}>
        <SwellsTableResults vesselId={props.match.params.id} />
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.tabContent}>
        <CurrentsTableResults vesselId={props.match.params.id} />
      </TabPanel>
      <TabPanel value={value} index={5} className={classes.tabContent}>
        <Charts vesselId={props.match.params.id} />
      </TabPanel>
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minHeight: "calc(100vh - 50px)",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "inherit",
    marginTop: "8px",
    "& button": {
      minWidth: "auto",
      padding: "6px 25px",
      paddingLeft: "0",
      margin: " auto auto auto 20px",
    },
  },
  tabContent: {
    width: "100%",
  },
}));
